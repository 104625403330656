<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>

      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
        <router-view :userData="userData"></router-view>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  computed: {
    userData() {
      return this.$store.getters['user/GetLocalUserData']
    },
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { id: 1, title: 'General info', route: '/profile/edit', icon: mdiInformationOutline },
      { id: 2, title: 'Linked accounts', route: '/profile/edit/accounts', icon: mdiInformationOutline },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
